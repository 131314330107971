import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this


let existingAbility = []

try {
    const data = JSON.parse(localStorage.getItem('is_finalised'))
    if (data) {
        existingAbility = [{ action: 'manage', subject: 'overview' }, { action: 'manage', subject: 'allusers' }, { action: 'manage', subject: 'suspenduser' }, { action: 'manage', subject: 'event' }, { action: 'manage', subject: 'complaints' }, { action: 'manage', subject: 'invite' }, { action: 'manage', subject: 'verification' }, { action: 'manage', subject: 'groups' }, { action: 'manage', subject: 'settings' }]
    } else {
        existingAbility = [{ action: 'manage', subject: 'mandatory' }, { action: 'manage', subject: 'suggested' }, { action: 'manage', subject: 'setupown' }, { action: 'manage', subject: 'module' }, { action: 'manage', subject: 'preview' }, { action: 'manage', subject: 'color' }]
    }
} catch {
    console.log("error")
    existingAbility = [{ action: 'manage', subject: 'mandatory' }, { action: 'manage', subject: 'suggested' }, { action: 'manage', subject: 'setupown' }, { action: 'manage', subject: 'module' }]
}


export default new Ability(existingAbility || initialAbility)
